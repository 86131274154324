import { useMemo, useState } from 'react';
import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Document, PDFViewer, BlobProvider, Page, View } from '@react-pdf/renderer';
import filter from 'lodash/filter';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';

import Dialog from '@components/Dialog';
import { ReportProps } from '@pages/reports/ReportCard';
import PdfMailingLabels from '@components/pdf/PdfMailingLabels';
import _, { first, keys, pickBy, sortBy, uniq } from 'lodash';
import { PageSize } from '@typedefs/app';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import { PdfTableData, PdfTableHeaderProps } from '@components/pdf';

// Create styles

// ----------------------------------------------------------------------
export default function Report({ id }: ReportProps) {
  const navigate = useNavigate();
  const { fFullName } = useFormat();
  const { donors } = useDonation();

  // Get a sorted list of the years of non-zero memberNumbers, as strings.
  const years = useMemo(
    () =>
      _(donors) // _ is lodash chaining
        .map((d) => keys(pickBy(d.memberNumbers, (n) => n > 0))) // Extract keys (years) with values > 0
        .flatten() // Flatten the arrays of keys into a single array
        .uniq() // Remove duplicates
        .sortBy() // Sort the keys in ascending order
        .reverse() // Reverse the order for descending sort
        .value(), // Extract the final value from the chain
    [donors]
  );

  const [yearSelected, setYearSelected] = useState(Number(first(years)));

  // ---------- form state
  const [labelsPerPage, setLabelsPerPage] = useState(20);
  const [startingTop, setStartingTop] = useState(0.65);
  const [startingLeft, setStartingLeft] = useState(0.35);
  const [skipLabels, setSkipLabels] = useState<number>();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  // ---------- memo
  const data: PdfTableData = useMemo(() => {
    // This header is only used with the CSV export
    const header: PdfTableHeaderProps[] = [
      { name: 'Envelope #', width: 26 },
      { name: 'Name', width: 26 },
    ];

    const filteredDonors = filter(donors, (d) => d.memberNumbers[yearSelected] > 0);
    const unsortedItems = uniq(filteredDonors).map((d) => ({
      fullName: fFullName(d),
      envelopeNumber: d.memberNumbers[yearSelected],
      envelopeNumberDisplay: `Envelope # ${d.memberNumbers[yearSelected]}`,
    }));
    const sorted = sortBy(unsortedItems, (d) => d.envelopeNumber);
    const items = sorted.map((s) => [s.envelopeNumberDisplay, s.fullName] as string[]);
    return { header, items };
  }, [donors, fFullName, yearSelected]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  // Because this report doesn't use PdfTable, have to replicate
  // the surrounding <Document> element, then <PdfMailingLabels> does the rest.
  const ReportDoc = useMemo(
    () => (
      <Document
        title="Envelope Box Labels"
        author="Software4Nonprofits"
        creator="s4np web-app"
        producer="s4np web-app"
        pdfVersion="1.7"

        // onRender={(blob) => console.log('doc render: ', blob)}
      >
        {data.items.length > 0 ? (
          <PdfMailingLabels
            data={data.items}
            labelsPerPage={labelsPerPage}
            startingTop={startingTop}
            startingLeft={startingLeft}
            skipLabels={skipLabels || 0}
            size={'letter'}
            fontSize={14}
          />
        ) : (
          <Page size={PageSize.letter}>
            <View />
          </Page>
        )}
      </Document>
    ),
    [data, labelsPerPage, skipLabels, startingLeft, startingTop]
  );

  const handleChangeLabelsPerPage = (e: SelectChangeEvent<number>) => {
    setLabelsPerPage(Number(e.target.value));
  };

  return (
    <Dialog
      title="Envelope Box Labels"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!blob || !!error || data.items.length === 0}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'envelope_box_labels'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography>For year: </Typography>
              <Autocomplete
                id="select-year"
                size="small"
                options={years}
                sx={{ minWidth: 100 }}
                autoComplete
                autoHighlight
                disableClearable
                freeSolo={false}
                value={yearSelected.toString()}
                onChange={(e, newValue) => setYearSelected(Number(newValue))}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>

            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Labels per Page
              </Typography>
              <Select
                id="select-labels-per-page"
                value={labelsPerPage}
                onChange={handleChangeLabelsPerPage}
              >
                <MenuItem key={20} value={20}>
                  20 (1" x 4")
                </MenuItem>
                <MenuItem key={30} value={30}>
                  30 (1" x 2.65")
                </MenuItem>
              </Select>
              <Stack spacing={2} direction="column" width={'100%'}>
                <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                  Starting Margins and Skipped Labels
                </Typography>
                <Stack spacing={2} direction="row" width={'100%'}>
                  <TextField
                    label="Left Margin (in)"
                    value={startingLeft}
                    onChange={(e) => setStartingLeft(Number(e.target.value))}
                    type="number"
                  />
                  <TextField
                    label="Top Margin (in)"
                    value={startingTop}
                    onChange={(e) => setStartingTop(Number(e.target.value))}
                    type="number"
                  />
                </Stack>
                <TextField
                  label="Initial Labels to Skip"
                  value={skipLabels}
                  onChange={(e) => setSkipLabels(Number(e.target.value))}
                  type="number"
                />
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
