import { useMemo } from 'react';
import {
  orderBy as _orderBy,
  cloneDeep,
  filter,
  intersection,
  isString,
  sortBy,
  uniq,
} from 'lodash';
import Fuse from 'fuse.js';

import { TColumn, TColumnFilter } from '@typedefs/app';
import { Category, Tag } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import evaluateFilter from '@components/table/TableFilters/evaluateFilter';
import { DonorListHeader, DonorListItem } from './config';
import getMemberEnvNumber from '@utils/getMemberEnvNumber';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<DonorListHeader>[];
  readonly dateFrom?: Date;
  readonly dateTo?: Date;
  readonly order: 'asc' | 'desc';
  readonly orderBy: DonorListHeader;
  readonly search: string;

  // filters
  readonly filterTags: Tag.Tag[];
  readonly filterCategories: Category.Category[];
  readonly filters: TColumnFilter<DonorListHeader>[];
};
// ----------------------------------------------------------------------
export default function useData({ columns, search, order, orderBy, ...filters }: Props) {
  const {
    donorsWithDonations,
    getTagsFromIds,
    getCategoriesFromIds,
    getPaymentMethodById,
    paymentMethods,
  } = useDonation();
  const { fAddress, fFullName, fReversedName, fDate, fCurrency } = useFormat();
  // We are forcing all columns to be searchable, if we don't omit the condition in the next
  // line, stored views will have them non-searchable!
  const searchableColumns = columns.filter((c) => c.visible /* && c.searchable */).map((c) => c.id);
  const currentYear = new Date().getFullYear();

  // ----- parsing -------
  const parsedData = useMemo(
    () =>
      donorsWithDonations.map((donor): DonorListItem => {
        const categories = sortBy(
          getCategoriesFromIds(uniq(donor.donations.map((d) => d.categoryId))),
          'name'
        );
        const tags = sortBy(getTagsFromIds(donor.tagIds), (t) => t.name.toUpperCase());
        const catIds = filters.filterCategories.map((c) => c.id);
        const filteredDonations = donor.donations.filter((d) => {
          if (catIds.length && !catIds.includes(d.categoryId)) return false;
          const donationTime = new Date(d.date).getTime();
          if (
            (filters.dateFrom?.getTime() || 0) >= donationTime ||
            (filters.dateTo?.getTime() || Infinity) <= donationTime
          )
            return false;
          // Any paymentMethods filter is about the donor's donations, apply it here
          const paymentMethodFilter = filters.filters.find((f) => f.columnId === 'paymentMethods');
          if (paymentMethodFilter) {
            const paymentMethod = getPaymentMethodById(d.paymentMethodId)?.name;
            return evaluateFilter(paymentMethod, paymentMethodFilter);
          }
          return true;
        });
        return {
          _donor: donor,
          _tags: tags,
          _categories: categories,
          _paymentMethods: paymentMethods,
          _donationsCount: filteredDonations.length,
          _donationsTotal: filteredDonations.reduce((acc, d) => (acc += d.amount), 0),

          id: donor.id,
          createdAt: fDate(donor._meta.createdAt),
          name: fFullName(donor, currentYear, filters.dateFrom, filters.dateTo),
          reversedName: fReversedName(donor, currentYear, filters.dateFrom, filters.dateTo),
          email: donor.email || '',
          phone: donor.phone,
          address: fAddress(donor.address),
          date: donor.donationDate ? fDate(donor.donationDate) : '',
          amount: fCurrency(filteredDonations.reduce((acc, d) => (acc += d.amount), 0)),
          donationsCount: filteredDonations.length.toString(),
          tags: tags.map((c) => c!.name).join(', '),
          categories: categories.map((c) => c!.name).join(', '),
          paymentMethods: uniq(
            donor.donations.map((d) => paymentMethods.find((p) => p.id === d.paymentMethodId)?.name)
          )
            .filter((d) => d)
            .join(', '),
          nonReceiptable: !!donor.nonReceiptable ? 'Yes' : 'No',
          notes: donor.notes,
          memberNumber: getMemberEnvNumber(
            donor,
            currentYear,
            filters.dateFrom,
            filters.dateTo
          ).toString(),
          custom1: donor.customFields?.[0] || '',
          custom2: donor.customFields?.[1] || '',
          custom3: donor.customFields?.[2] || '',
          custom4: donor.customFields?.[3] || '',
          custom5: donor.customFields?.[4] || '',
          custom6: donor.customFields?.[5] || '',
        };
      }),
    [
      donorsWithDonations,
      getCategoriesFromIds,
      getTagsFromIds,
      paymentMethods,
      fDate,
      fFullName,
      currentYear,
      fReversedName,
      fAddress,
      fCurrency,
      getPaymentMethodById,
      filters,
    ]
  );

  // ----- FILTERING -------
  const filteredData = useMemo(() => applyFilters(parsedData, filters), [parsedData, filters]);

  // ----- SEARCHING -------
  const fuse = useMemo(
    () =>
      new Fuse(filteredData, {
        keys: searchableColumns,
        includeMatches: true,
        includeScore: false,
        threshold: 0.2,
        ignoreLocation: true,
        shouldSort: false,
      }),
    [filteredData, searchableColumns]
  );

  const searchedData = useMemo(
    () =>
      search
        ? fuse.search(search).map(({ item, matches }) => {
            let clone = cloneDeep(item) as any;
            matches?.forEach(({ key, indices }) => {
              let prop = clone[key as string];
              if (isString(prop)) {
                [...indices].reverse().forEach(([start, finish]) => {
                  prop = prop.substring(0, finish + 1) + '</strong>' + prop.substring(finish + 1);
                  prop = prop.substring(0, start) + '<strong>' + prop.substring(start);
                });
                clone[key as string] = prop;
              }
            });

            return clone as DonorListItem;
          })
        : ((fuse as any)._docs as DonorListItem[]),
    [fuse, search]
  );

  // ----- ORDERING -------
  return useMemo(() => {
    switch (orderBy) {
      case 'createdAt':
        return _orderBy(searchedData, (f) => new Date(f._donor._meta.createdAt).getTime(), order);
      case 'date':
        return _orderBy(
          searchedData,
          (f) => (f._donor.donationDate ? new Date(f._donor.donationDate).getTime() : undefined),
          order
        );
      case 'amount':
        return _orderBy(searchedData, (f) => f._donationsTotal, order);
      case 'donationsCount':
        return _orderBy(searchedData, (f) => f._donationsCount, order);
      case 'memberNumber':
        return _orderBy(
          searchedData,
          (f) => {
            if (!f.memberNumber) {
              return order === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
            }
            return parseInt(f.memberNumber);
          },
          order
        );
      default:
        return _orderBy(searchedData, orderBy, order);
    }
  }, [searchedData, orderBy, order]);
}

// ----------------------------------------------------------------------
type FilterProps = Omit<Props, 'columns' | 'search' | 'order' | 'orderBy'>;
function applyFilters(data: DonorListItem[], dataProps: FilterProps): DonorListItem[] {
  return filter(data, (item) =>
    [
      applyTagsFilter(item, dataProps.filterTags),
      applyColumnFilters(item, dataProps.filters),
    ].every(Boolean)
  );
}

// ------------------------------
function applyTagsFilter(item: DonorListItem, tags: Tag.Tag[]) {
  const tagIds = tags.filter((t) => t.type === 'donor').map((t) => t.id);
  const donorTagIds = item._tags.map((t) => t.id);
  return !tagIds.length || intersection(tagIds, donorTagIds).length > 0;
}
// ------------------------------
const getValue = (item: DonorListItem, columnId: DonorListHeader): any => {
  switch (columnId) {
    case 'createdAt':
      return item._donor._meta.createdAt;
    case 'date':
      return item._donor.donationDate;
    case 'amount':
      return item._donor.donationsTotal;
    default: {
      return (item as any)[columnId];
    }
  }
};

// Apply all of the filters to the donor, ignoring paymentMethods which is about
// the included donations and applied elsewhere.
function applyColumnFilters(item: DonorListItem, filters: TColumnFilter<DonorListHeader>[]) {
  return filters.every(
    (filter) =>
      filter.columnId === 'paymentMethods' ||
      evaluateFilter(getValue(item, filter.columnId), filter)
  );
}
