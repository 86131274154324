import { useSnackbar } from 'notistack';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/react';

import * as analytics from '@fire/analytics';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { ReceiptIssueSchema, ReceiptIssueSchemaForm, ReceiptOptions } from '@/schemas';
import { Country, Receipt } from '@shared/types';
import { PageSize } from '@typedefs/app';
import { TDonorWithDonations, TReceiptDonorGroup } from '@typedefs/donation';
import blobToDataUrl from '@utils/blobToDataUrl';

import useSteps, { StepId } from '../useSteps';
import StepSendType from './steps/SendType';
import StepRecipients from './steps/Recipients';
import StepReviewReceipt from './steps/ReviewReceipt';
import StepReviewEmail from './steps/ReviewEmail';
import {
  renderTemplate,
  defaultState,
  defaultSubject,
  renderSubject,
} from './steps/ReviewEmail/EmailTemplate';
import StepOverview from './steps/Overview';
import useQueryParams from '@hooks/useQueryParams';
// ----------------------------------------------------------------------
export default function ReceiptsCreate() {
  const { org } = useOrg();
  // TODO: get org email subject and body S4NP-109
  const {
    createReceipts,
    sendReceiptEmail,
    getReceiptPartitions,
    getReceiptIssueGroups,
    donorsWithDonations,
    getDonorById,
  } = useDonation();
  const { fDateToISO, fFullName } = useFormat();
  const { enqueueSnackbar } = useSnackbar();
  const options: ReceiptOptions = {
    showDescription: !!org?.receiptDetailsTableDescription,
    includeDetails: org?.receiptDetailsTable ?? true,
    includeSummary: org?.receiptSummaryTable ?? true,
  };
  const { queryParams } = useQueryParams();

  // --------------- form ---------------
  const onComplete = (stepData: ReceiptIssueSchemaForm) => onSubmit(stepData);
  const renderSteps = (stepData: ReceiptIssueSchemaForm) => [
    {
      id: StepId.sendType,
      title: 'Send Type',
      Component: StepSendType,
    },
    {
      id: StepId.recipientList,
      title: 'Recipient List',
      Component: StepRecipients,
    },
    {
      id: StepId.receiptReview,
      title: 'Receipt Review',
      Component: StepReviewReceipt,
    },
    ...(stepData.actionType === 'email'
      ? [
          {
            id: StepId.emailReview,
            title: 'Email Review',
            Component: StepReviewEmail,
          },
        ]
      : []),
    {
      id: StepId.overview,
      title: 'Final Submission',
      Component: StepOverview,
    },
  ];
  const singleDonor = getDonorById(queryParams.donorId || undefined);
  const { ui, setErrors, setFailed, setSubmittingData, handleClose } = useSteps(
    {
      Schema: ReceiptIssueSchema,
      onComplete,
      renderSteps,
      title: `Issue receipts${!!singleDonor ? ' for ' + fFullName(singleDonor) : ''}`,
      actionText: 'Issue Receipts',
    },
    {
      singleDonor: singleDonor,
      donors: [],
      email: {
        subject: org?.receiptEmailSubject || defaultSubject,
        html: '',
        state: org?.receiptEmailBody || defaultState,
      },
    }
  );

  // --------------- actions ---------------
  const onSubmit = async (stepData: ReceiptIssueSchemaForm) => {
    setSubmittingData({ isSubmitting: true, progress: 1 });

    try {
      // this throws an error in validation
      await ReceiptIssueSchema.validate(stepData, { abortEarly: false });

      const { actionType, donors, letter, email } = stepData;
      if (!org || !actionType || !donors.length || !letter.size || !letter.Doc) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
        throw { errors: ['Missing required data!'] };
      }

      if (actionType === 'email') {
        if (!email.subject || !email.html) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
          throw { errors: ['Missing email data!'] };
        }
      }

      // const t = Sentry.startTransaction({
      //   name: 'Issue receipts',
      //   op: 'processReceipts',
      //   data: { type: actionType },
      // }) as Sentry.Transaction;

      // INFO: we are remapping donors from this form to latest data in the app
      // this is because data in the form might be stale if process takes too long
      // however... this might cause side-effect if someone updates donor/donation that are chosen here
      // as they might escape our filtering and/or update fields we would not want to receipt out
      const receiptDonorIds = donors.map((d) => d.id);
      const remappedDonors = donorsWithDonations
        .filter((d) => receiptDonorIds.includes(d.id))
        .map((d) => {
          const receiptDonor = donors.find((formDonor) => formDonor.id === d.id) || d;
          const remappedDonations = d.donations.filter((don) =>
            receiptDonor.donations.find((receiptDonation) => receiptDonation.id === don.id)
          );
          return {
            ...d,
            donations: remappedDonations,
          };
        });

      // generate receipts
      const { donorsWithReceipts, receiptDonorGroups } = await issueReceipts(
        org.address.country,
        actionType,
        remappedDonors
      );

      // give outcome
      if (actionType === 'email') {
        await processReceiptsEmail(receiptDonorGroups, donorsWithReceipts, stepData);
      } else {
        await processReceiptsPrint(receiptDonorGroups, donorsWithReceipts, stepData);
      }

      enqueueSnackbar('Receipts issued!');
      analytics.donation.receiptCreate(receiptDonorGroups.length, actionType);
      handleClose();
    } catch (e) {
      enqueueSnackbar('Error issuing receipts!', { variant: 'error' });
      Sentry.captureException(e, {
        extra: {
          receiptFlow: 'create',
          ...stepData,
          donors: stepData.donors.length,
        },
      });
      console.error(e);
      setErrors(e.errors);
      setFailed();
    }

    setSubmittingData({ isSubmitting: false, progress: 0 });
  };

  // --------------- issuing ---------------
  const issueReceipts = async (
    country: Country,
    actionType: Receipt.ReceiptActionType,
    donors: TDonorWithDonations[]
  ) => {
    setSubmittingData({ isSubmitting: true, progress: 2 });

    // const spanApi = t.startChild({
    //   // data: { totalBytes: batchSize },
    //   op: 'call_api',
    //   description: 'Calls backend to issue receipts',
    // });

    // we limit donations to within a single year, and any donation carries that value
    const receiptDate = new Date(donors[0].donations[0].date);
    const year = receiptDate.getFullYear();
    const date = fDateToISO();

    // create groups of donations for receipt generation
    const partitions = getReceiptPartitions(country, donors);
    const { issuedReceipts, receiptingDonations } = await createReceipts({
      orgId: org!.id,
      date,
      year,
      actionType,
      partitions,
    });

    // update donors with updated donations
    const donorsWithDonations: TDonorWithDonations[] = donors.map((donor) => ({
      ...donor,
      donations: receiptingDonations.filter((d) => d.donorId === donor.id),
    }));

    const receiptDonorGroups = getReceiptIssueGroups(org!, partitions, issuedReceipts);

    // spanApi.setStatus('ok');
    // spanApi.finish();
    return { donorsWithReceipts: donorsWithDonations, receiptDonorGroups };
  };

  // --------------- email ---------------
  const processReceiptsEmail = async (
    receiptDonorGroups: TReceiptDonorGroup[],
    donors: TDonorWithDonations[],
    data: ReceiptIssueSchemaForm
  ) => {
    const { email, letter } = data;
    if (!org || !letter.Doc) return;

    // -------------------- generation --------------------
    setSubmittingData({ isSubmitting: true, progress: 3 });

    // const spanReceipts = t.startChild({
    //   data: {
    //     donorCount: donors.length,
    //     donationCount: donors.reduce((acc, d) => (acc += d.donations.length), 0),
    //   },
    //   op: 'generate_receipts',
    //   description: 'Generates receipts for each donor',
    // });

    let batch: Receipt.ReceiptEmail[] = [];
    let batchSize = 0;

    // NOTE: issues emails and resets the batch
    const issueEmailBatch = async () => {
      // const spanEmail = t.startChild({
      //   data: { batchSize, batchCount: batch.length },
      //   op: 'send_email_batch',
      //   description: 'Sends email batch',
      // });
      await sendReceiptEmail({
        orgId: org.id,
        emailSignature: org.emailSignature!,
        emails: [...batch],
      });
      batch = [];
      batchSize = 0;
      // spanEmail.setStatus('ok');
      // spanEmail.finish();
    };

    // iterate each donor and add to the batch
    let i = donors.length;
    while (i--) {
      const donor = donors[i];

      const donorGroup = receiptDonorGroups.filter(({ donorId }) => donorId === donor.id);
      const pdfDoc = (
        <letter.Doc
          title="Receipt"
          description="Receipt"
          size={PageSize[letter.size!]}
          donorGroups={donorGroup}
          withLetter={data.letter.withLetter}
          options={options}
          year={donorGroup[0].groups[0].year}
        />
      );
      const blob = await pdf(pdfDoc).toBlob();
      const attachment = await blobToDataUrl(blob, true);

      batch.push({
        to: `${fFullName(donor)} <${donor.email!}>`,
        subject: renderSubject(donorGroup[0].vars, email.subject),
        body: renderTemplate(donorGroup[0].vars, email.html),
        attachment,
      });

      const { size } = blob; // bytes
      batchSize += size;
      // in case we get big in size, issue a separate batch
      // firebase functions have a limit of 10mb body size, so we cap on 5mb
      // 1000000 = 1mb
      if (batchSize >= 5 * 1e6) {
        await issueEmailBatch();
      }
    }
    // spanReceipts.setStatus('ok');
    // spanReceipts.finish();

    // -------------------- send emails --------------------
    setSubmittingData({ isSubmitting: true, progress: 4 });

    // batching done, check if batch is not empty
    // if not empty, execute batch
    if (batch.length) {
      await issueEmailBatch();
    }

    // if on top of emailing, user wants to print
    if (data.emailAndPrint) {
      await processReceiptsPrint(receiptDonorGroups, donors, data);
    }

    // t.finish();
  };

  // --------------- print ---------------
  const processReceiptsPrint = async (
    receiptDonorGroups: TReceiptDonorGroup[],
    donors: TDonorWithDonations[],
    data: ReceiptIssueSchemaForm
  ) => {
    const { letter } = data;
    if (!org || !letter.Doc) return;

    // -------------------- generation --------------------
    setSubmittingData({ isSubmitting: true, progress: 3 });
    // const spanReceipts = t.startChild({
    //   data: {
    //     donorCount: donors.length,
    //     donationCount: donors.reduce((acc, d) => (acc += d.donations.length), 0),
    //   },
    //   op: 'generate_receipts',
    //   description: 'Generates receipts for each donor',
    // });

    const pdfDoc = (
      <letter.Doc
        title="Receipt letter"
        description="Receipt letter"
        size={PageSize[letter.size]}
        donorGroups={receiptDonorGroups}
        withLetter={data.letter.withLetter}
        options={options}
        year={receiptDonorGroups[0].groups[0].year}
      />
    );
    const blob = await pdf(pdfDoc).toBlob();

    // spanReceipts.setStatus('ok');
    // spanReceipts.finish();

    // -------------------- zipping --------------------
    setSubmittingData({ isSubmitting: true, progress: 4 });

    saveAs(blob, `${fDateToISO()}-receipts.pdf`);
    // t.finish();
  };

  return ui;
}
