import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { groupBy, round } from 'lodash';

import { ReceiptReissueBatchSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import Iconify from '@components/Iconify';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
export default function Overview({
  stepData,
  submittingData,
  updateStepData,
}: TStepProps<ReceiptReissueBatchSchemaForm>) {
  const total = 4;
  const normalize = (value: number) => ((value - 0) * 100) / (total - 0);
  const progress = round(normalize(submittingData.progress));
  const donorCount = Object.keys(groupBy(stepData.receipts, 'donorId')).length;
  const receiptCount = stepData.receipts.length;
  const { org } = useOrg();

  const handleSelectPrintAllDonors = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ emailAndPrint: e.target.checked });
  };

  return (
    <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
      <Card
        sx={{
          py: 4,
          px: 8,
          width: '50%',
          border: (theme) => `1px solid ${theme.palette.grey[600]}40`,
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {submittingData.isSubmitting ? (
          <Stack spacing={3} textAlign="center">
            <Typography variant="h6">Issuing receipts... </Typography>

            <Stack direction="column" spacing={1}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Stack spacing={2} textAlign="center">
              <Typography variant="h5">Are you ready to reissue Receipts?</Typography>
              <Typography variant="h6" color="text.secondary">
                <strong>{receiptCount}</strong> receipts to <strong>{donorCount}</strong> donors
              </Typography>
            </Stack>

            {stepData.actionType === 'email' && (
              <>
                <Stack spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stepData.emailAndPrint}
                          onChange={handleSelectPrintAllDonors}
                        />
                      }
                      label={
                        <Typography variant="body2" color="text.secondary">
                          Additionally, <strong>download a PDF of all receipts</strong> upon
                          "Reissue", for your own records.
                          <br />
                          <strong>Note:</strong> This PDF should not be distributed to donors, as it
                          would duplicate the attachment they receive via this email send.
                        </Typography>
                      }
                      sx={{ alignItems: 'flex-start', pl: 1.3 }}
                    />
                  </FormGroup>

                  {receiptCount > 0 &&
                    org?.emailSignature?.signatoryEmail.toLowerCase().endsWith('@gmail.com') && (
                      <Alert
                        severity="info"
                        icon={false}
                        sx={{
                          '.MuiAlert-message': { px: 2 },
                          border: (theme) => `1px solid ${theme.palette.grey[600]}14`,
                          background: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems="flex-start"
                          color="text.secondary"
                        >
                          <IconButton
                            size="small"
                            sx={{ color: (theme) => theme.palette.warning.dark }}
                          >
                            <Iconify icon="ic:baseline-warning-amber" width={24} height={24} />
                          </IconButton>
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            <strong>Important Notice for Gmail Users</strong>
                            <br />
                            If you are using a personal Gmail account to send receipts, please be
                            aware that Google imposes daily sending limits. These limits are managed
                            by Google, and we have no control over them.
                            <br />
                            Please note, we cannot monitor or verify whether emails are successfully
                            sent. If any issues arise, you'll need to check directly with your Gmail
                            account.
                            <br />
                            For additional information on Gmail sending limits, please check here:{' '}
                            <strong>
                              <Link
                                href="https://support.google.com/a/answer/166852?hl=en#zippy="
                                target="_blank"
                              >
                                Details
                              </Link>
                            </strong>
                          </Typography>
                        </Stack>
                      </Alert>
                    )}
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Card>
    </Stack>
  );
}
