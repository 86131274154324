import { useState } from 'react';
import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Role } from '@shared/types/organization';
import { useSnackbar } from 'notistack';
import EmailStatus from '../EmailStatus';
import { RHFTextField } from '@components/hook-form';

export default function GmailSignatoryForm() {
  const { org, createGmailSenderSignature } = useOrg();
  const { hasAccess } = useRole();
  const { enqueueSnackbar } = useSnackbar();
  const canManage = hasAccess([Role.editor, Role.contributor]);
  const [isConnecting, setIsConnecting] = useState(false);

  // -------- Actions --------
  const onConnect = async () => {
    setIsConnecting(true);
    if (!org) {
      enqueueSnackbar('OrgId is missing', { variant: 'error' });
      return;
    }
    if (!canManage) {
      enqueueSnackbar('You are not authorized to do this action!', { variant: 'error' });
      return;
    }

    const url: string = await createGmailSenderSignature({
      orgId: org.id,
      signatoryName: org.signatoryName,
    });
    window.open(url);
  };
  return (
    <>
      <Stack>
        <Typography variant="caption" sx={{ pb: 2 }}>
          This setup is for emails that look like “name@gmail.com”.
        </Typography>
        {org?.emailSignature?.signatoryEmail ? null : (
          <>
            <Typography variant="caption">Step 1: Click “Connect Account” below</Typography>
            <Typography variant="caption">
              Step 2: Follow the steps for Gmail authentication
            </Typography>
            <LoadingButton onClick={onConnect} loading={isConnecting} variant="contained" sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Connect Account</Typography>
            </LoadingButton>
          </>
        )}
        {org?.emailSignature?.signatoryEmail ? (
          <>
            <RHFTextField
              disabled={true}
              name="signatoryEmail"
              label="Signatory Email"
              helperText="Email address from which user will receive an email"
              inputProps={{ maxLength: 60 }}
            />
            <br />
            <EmailStatus signature={org?.emailSignature} />
          </>
        ) : null}
      </Stack>
    </>
  );
}
