import { Category, Donor, Meta, PaymentMethod } from '../index';
export * from './requests';

export enum EndingOption {
  Never = 'never',
  On = 'on',
  After = 'after',
}

export enum PeriodType {
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  Monthly = 'Monthly',
}

export type RecurringDonation = {
  readonly orgId: string;
  readonly _meta: Meta;
  readonly id?: string | null | undefined;
  readonly donorId: string;
  readonly amount: number;
  readonly categoryId: string | null;
  readonly paymentMethodId: string | null;
  readonly startDate: string;
  readonly nonReceiptable: boolean;
  readonly period: PeriodType;
  readonly endingOption: EndingOption;
  readonly endDate: string | null;
  readonly occurrenceLimit: number | null;
  readonly nextOccurrence?: string | null;
  readonly v1MemId?: number;
};

export type RecurringDonationUpdate = {
  readonly orgId: string;
  readonly _meta: Meta;
  readonly id?: string | null | undefined;
  readonly amount: number;
  readonly categoryId: string | null;
  readonly paymentMethodId: string | null;
  readonly nonReceiptable: boolean;
};

// ----------------------------------------------------------------------
export type RecurringDonationForm = {
  readonly id?: string | null | undefined;
  readonly donor: Donor.Donor | null;
  readonly amount: number | undefined;
  readonly category: Category.Category | null;
  readonly startDate: Date;
  readonly paymentMethod: PaymentMethod.PaymentMethod | null;
  readonly nonReceiptable: boolean;
  readonly period: PeriodType;
  readonly endingOption: EndingOption;
  readonly endDate?: Date | null;
  readonly occurrenceLimit: number | null;
  readonly nextOccurrence?: Date | null;
  afterSubmit?: string;
};
