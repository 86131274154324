// Output the PDF document for mailing labels
import { useMemo } from 'react';
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import groupBy from 'lodash/groupBy';

import { PageSize } from '@typedefs/app';

type MailingLabel = {
  page: number;
  left: number;
  top: number;
  lines: string[];
};

type Props = {
  data: string[][];
  labelsPerPage: number;
  startingTop: number;
  startingLeft: number;
  skipLabels: number;
  size: PageSize;
  fontSize?: number;
};

// ----------------------------------------------------------------------
export default function PdfMailingLabels({
  data,
  labelsPerPage,
  startingTop,
  startingLeft,
  skipLabels,
  size,
  fontSize=10,
}: Props) {
  // Calc the positioning and pagination of the labels, handles either 20 or 30 labelsPerPage
  const labels: MailingLabel[] = useMemo(() => {
    const labelsPerRow = labelsPerPage / 10;

    return data.map((d, index) => {
      // Want the labels to flow across each row, then down the page, having skipped skipLabels
      // at the start of page 1 so users can use up expensive partly-used label pages!
      const page = Math.floor((index + skipLabels) / labelsPerPage);
      const labelOnPage = (index + skipLabels) % labelsPerPage;
      // When there are 20 labelsPerPage, they are 4.25" apart, 2 across each row, 10 rows.
      // When there are 30 labelsPerPage, they are 2.875" apart, 3 across each row, 10 rows.
      const left =
        startingLeft +
        (labelsPerPage === 20
          ? labelOnPage % 2 === 0
            ? 0
            : 4.25
          : labelOnPage % 3 === 0
          ? 0
          : labelOnPage % 3 === 1
          ? 2.875
          : 5.75);
      // Which row is this label on - determines the top position.
      const top = startingTop + Math.floor(labelOnPage / labelsPerRow);
      return {
        page: page,
        left: left,
        top: top,
        lines: d,
      };
    });
  }, [data, labelsPerPage, startingLeft, startingTop, skipLabels]);

  const { width, height } = PageSize[size];

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Lato',
      fontSize: fontSize,
    },
  });
  
  // --------------------------------------------------
  const pageSize = { width, height };
  const grouped = groupBy(labels, 'page');
  return (
    <>
      {Object.keys(grouped).map((g, groupIndex) => (
        <Page key={groupIndex} style={styles.page} size={pageSize}>
          {grouped[g].map((label, labelIndex) => (
            <View
              key={labelIndex}
              style={{ position: 'absolute', left: `${label.left}in`, top: `${label.top}in` }}
            >
              {label.lines.map((l, lineIndex) => (
                <Text key={lineIndex}>{l}</Text>
              ))}
            </View>
          ))}
        </Page>
      ))}
    </>
  );
}
