import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  InputAdornment,
  Stack,
  Typography,
  Alert,
  MenuItem,
  FormControl,
  Tooltip,
  Box,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm, useWatch } from 'react-hook-form';
import { filter, isArray, sortBy, isUndefined } from 'lodash';
import {
  addMonths,
  addWeeks,
  addYears,
  getDate,
  isAfter,
  isBefore,
  isFuture,
  lastDayOfMonth,
  subYears,
  isEqual,
  min,
} from 'date-fns';

import { Category, Donation, Donor, Organization } from '@shared/types';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import useFormat from '@hooks/useFormat';

import { renderCategoryOption } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';

import {
  RHFAutocomplete,
  RHFCheckbox,
  RHFDatePicker,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from '@components/hook-form';
import Dialog from '@components/Dialog';
import Iconify from '@components/Iconify';
import { renderPaymentMethodOption } from '@pages/donations/dialogs/Settings/PaymentMethods/utils/renderPaymentMethods';
import { PeriodType, EndingOption, RecurringDonationForm } from '@shared/types/recurringDonation';
import { recurringDonationSchemaResolver } from '@/schemas/recurringDonation';
import PaywallDonorGuard from '@/guards/PaywallDonorGuard';
import DonorDialog from '@pages/donors/dialogs/Donor';
import CategoryDialog from '@pages/donations/dialogs/Settings/DonationCategories/Dialog';
import ConfirmDialog from '@components/ConfirmDialog';
import { useLocation, useParams } from 'react-router';
import useRole from '@hooks/useRole';
import {
  calculateNextOccurrence,
  getLabelFrequency,
  getNextFutureOccurrence,
} from '@utils/recurringDonation';
import { parseDateTimezone } from '@redux/slices/donation';
import { InfoIcon } from '@/theme/overrides/CustomIcons';

const alertEditMessage =
  'Only the donation details can be changed when you are updating a recurring donation.';

type Props = {
  readonly recurringDonationId?: string;
  readonly open?: boolean;
  readonly onClose?: () => void;
};

// ----------------------------------------------------------------------
export default function DonationRecurring({ open, recurringDonationId, onClose }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const params = useParams();
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  const [createDonorOpen, setCreateDonorOpen] = useState(false);
  const [isConfirmationCreate, setConfirmationCreate] = useState(false);
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { fReversedName, fDateToISO, fShortDate, fDateToYearStart } = useFormat();
  const { org } = useOrg();
  const { hasAccess } = useRole();

  const {
    categories,
    donors,
    getRecurringDonationById,
    createRecurringDonation,
    updateRecurringDonation,
    deleteRecurringDonation,
    paymentMethods,
    getCategoryById,
    getDonorById,
    getPaymentMethodById,
  } = useDonation();

  const recId = recurringDonationId || params.recurringDonationId;

  const path = pathname.split('/');
  const isFromMenu = path[path.length - 2] === 'recurring-donation';

  const editRecurringDonation = useMemo(
    () => getRecurringDonationById(recId),
    [getRecurringDonationById, recId]
  );

  const isEdit = useMemo(() => !!editRecurringDonation, [editRecurringDonation]);

  const donor = useMemo(
    () => (editRecurringDonation && getDonorById(editRecurringDonation.donorId)) || null,
    [editRecurringDonation, getDonorById]
  );

  const category = isEdit
    ? (editRecurringDonation?.categoryId && getCategoryById(editRecurringDonation?.categoryId!)) ||
      null
    : getCategoryById(org?.defaultCategoryId) || null;

  const paymentMethod = isEdit
    ? (editRecurringDonation?.paymentMethodId &&
        getPaymentMethodById(editRecurringDonation.paymentMethodId!)) ||
      null
    : getPaymentMethodById(org?.defaultPaymentMethodId) || null;

  const defaultValues: RecurringDonationForm = useMemo(
    () => ({
      donor,
      amount: editRecurringDonation?.amount,
      category: category,
      paymentMethod: paymentMethod,
      nonReceiptable: editRecurringDonation?.nonReceiptable || false,
      startDate:
        (editRecurringDonation && parseDateTimezone(editRecurringDonation.startDate!)) ||
        new Date(),
      period: editRecurringDonation?.period || PeriodType.Monthly,
      endingOption: editRecurringDonation?.endingOption || EndingOption.Never,
      endDate:
        (editRecurringDonation?.endDate && parseDateTimezone(editRecurringDonation.endDate)) ||
        null,
      occurrenceLimit: editRecurringDonation?.occurrenceLimit || null,
    }),
    [donor, editRecurringDonation, category, paymentMethod]
  );

  const paymentMethodsNoGIK = useMemo(
    () =>
      sortBy(
        filter(paymentMethods, (p) => p.type !== Donation.PaymentMethodInitialList.giftInKind),
        (p) => p.name.toUpperCase()
      ),
    [paymentMethods]
  );

  // --------------- form ---------------
  const recurringDonation = useForm<RecurringDonationForm>({
    resolver: recurringDonationSchemaResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitting },
  } = recurringDonation;

  const watchEndDate = watch('endDate');
  const watchEndingOption = watch('endingOption');
  const watchOccurrenceLimit = watch('occurrenceLimit');
  const watchStartDate = watch('startDate');
  const watchPeriod = watch('period');

  const watchedYear = new Date().getFullYear();

  const repetition = Object.values(PeriodType);

  const canEdit = hasAccess([Organization.Role.editor, Organization.Role.contributor]);

  // --------------- routing ---------------
  const handleClose = useCallback(() => {
    if (isUndefined(open)) {
      navigate(-1);
    } else {
      onClose?.();
    }
  }, [navigate, onClose, open]);

  const formDisabled = false;
  const donorList = useMemo(
    () =>
      sortBy(
        donors.map((d) => ({ ...d, name: fReversedName(d, watchedYear) })),
        'name'
      ),
    [fReversedName, donors, watchedYear]
  );

  // --------------- actions ---------------
  const handleDonorCreate = (newDonor?: Donor.Donor) => {
    if (newDonor) {
      setValue('donor', newDonor);
      clearErrors('donor');
    }
    setCreateDonorOpen(false);
  };

  const handleCategoryCreate = (newCategory?: Category.Category) => {
    if (newCategory) {
      setTimeout(() => {
        setValue('category', newCategory);
        clearErrors('category');
      }, 250);
    }
    setCreateCategoryOpen(false);
  };

  // return the number of donations up to the current date or end date
  const calculateImmediatelyDonation = useCallback(() => {
    const endDate = watchEndingOption === 'never' ? new Date() : min([watchEndDate!, new Date()]);

    let occurrenceCount = 0;

    let currentDate = watchStartDate;

    while (endDate && currentDate <= endDate) {
      occurrenceCount++;
      currentDate = calculateNextOccurrence(currentDate, watchPeriod);
    }

    return occurrenceCount;
  }, [watchEndDate, watchEndingOption, watchPeriod, watchStartDate]);

  // Calculate the date period - 1 periods after startDate (because the first occurrence is on the start date)
  const calculateEndDate = useCallback(
    (startDate: Date, period: string, numPeriods: number): Date => {
      switch (period) {
        case PeriodType.Weekly:
          return addWeeks(startDate, numPeriods - 1);
        case PeriodType.BiWeekly:
          return addWeeks(startDate, numPeriods * 2 - 1);
        case PeriodType.Monthly:
          const lastMonthDate = addMonths(startDate, numPeriods - 1);
          if (getDate(startDate) >= 28) {
            return lastDayOfMonth(lastMonthDate);
          }
          return lastMonthDate;
        default:
          throw new Error(`Unknown repeat type: ${period}`);
      }
    },
    []
  );

  const isExpired = () => watchEndingOption !== 'never' && !isFuture(watchEndDate!);

  const watchedValues = useWatch({
    control: recurringDonation.control,
    name: ['occurrenceLimit', 'endDate', 'startDate', 'period', 'endingOption'],
  });

  const handleUpdateDonation = useCallback(
    async (dataSchemaForm: RecurringDonationForm) => {
      const id = editRecurringDonation!.id!;
      try {
        await updateRecurringDonation({
          recurringDonationId: id,
          update: {
            orgId: org!.id,
            amount: dataSchemaForm.amount!,
            categoryId: dataSchemaForm.category!.id,
            paymentMethodId: dataSchemaForm.paymentMethod ? dataSchemaForm.paymentMethod.id : null,
            nonReceiptable: dataSchemaForm.nonReceiptable,
          },
        });
        enqueueSnackbar('Recurring Donation updated');
      } catch (error) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    },
    [enqueueSnackbar, setError, updateRecurringDonation, org, editRecurringDonation]
  );

  const onSubmit = useCallback(
    async (dataSchemaForm: RecurringDonationForm) => {
      if (!org) {
        setError('afterSubmit', { message: 'Missing organization' });
        return;
      }

      const numPeriods = dataSchemaForm.occurrenceLimit || 0;

      const endDatePeriod = numPeriods > 0
        ? calculateEndDate(dataSchemaForm.startDate, dataSchemaForm.period, numPeriods)
        : null;

      try {
        if (editRecurringDonation) {
          await handleUpdateDonation(dataSchemaForm);
        } else {
          await createRecurringDonation({
            orgId: org.id,
            donorId: dataSchemaForm.donor!.id,
            amount: dataSchemaForm.amount!,
            categoryId: dataSchemaForm.category!.id,
            paymentMethodId: dataSchemaForm.paymentMethod ? dataSchemaForm.paymentMethod.id : null,
            startDate: fDateToISO(dataSchemaForm.startDate!),
            nonReceiptable: dataSchemaForm.nonReceiptable,
            period: dataSchemaForm.period,
            endingOption: dataSchemaForm.endingOption,
            endDate: dataSchemaForm.endDate
              ? fDateToISO(dataSchemaForm.endDate)
              : endDatePeriod
                ? fDateToISO(endDatePeriod)
                : null,
            occurrenceLimit: dataSchemaForm.occurrenceLimit || null,
            nextOccurrence: fDateToISO(
              getNextFutureOccurrence(dataSchemaForm.startDate!, dataSchemaForm.period!)
            ),
          });
          enqueueSnackbar(`Recurring Donation Created!`);
        }

        handleClose();
      } catch (e) {
        console.error(e);
        setError('afterSubmit', { message: e.message });
      }
    },
    [
      org,
      handleClose,
      enqueueSnackbar,
      setError,
      createRecurringDonation,
      fDateToISO,
      calculateEndDate,
      handleUpdateDonation,
      editRecurringDonation,
    ]
  );

  const handleDelete = async () => {
    if (!canEdit) {
      setError('afterSubmit', { message: 'You lack permissions!' });
      return;
    }
    if (!org) {
      setError('afterSubmit', { message: '[internal] Missing organization!' });
      return;
    }
    if (!editRecurringDonation) {
      setError('afterSubmit', { message: 'Missing Recurring Donation to delete!' });
      return;
    }

    setDeleting(true);
    try {
      await deleteRecurringDonation({
        orgId: org.id,
        recurringDonationId: editRecurringDonation.id!,
      });
    } catch (e) {
      setError('afterSubmit', { message: e.message });
    }
    setDeleting(false);
    handleClose();
  };

  const handleConfirmCreation = () => {
    setConfirmationCreate(false);
    handleSubmit(onSubmit)();
  };

  // --------------- effects ---------------

  useEffect(() => {
    if (watchEndingOption === EndingOption.After && watchOccurrenceLimit != null) {
      const endDatePeriod = calculateEndDate(watchStartDate, watchPeriod, watchOccurrenceLimit);
      setValue('endDate', endDatePeriod);
    }
  }, [
    watchEndingOption,
    watchOccurrenceLimit,
    watchStartDate,
    watchPeriod,
    setValue,
    calculateEndDate,
  ]);

  return (
    <>
      {createDonorOpen && (
        <PaywallDonorGuard onLimitClose={handleDonorCreate}>
          <DonorDialog context="donations" onClose={handleDonorCreate} />
        </PaywallDonorGuard>
      )}
      <CategoryDialog open={createCategoryOpen} onClose={handleCategoryCreate} />
      <Dialog
        open={isUndefined(open) ? true : open}
        title={`${isEdit ? 'Edit' : 'Create'} Recurring Donation`}
        onClose={handleClose}
        maxWidth="md"
        isDirty={isDirty}
        isLoading={isSubmitting}
        methods={recurringDonation}
        helpInfoTitle={
          isFromMenu && (
            <Tooltip
              title="Make future edits to Recurring Donations in Settings → Recurring Donation tab."
              placement="right-start"
            >
              {!isEdit ? (
                <IconButton>
                  <InfoIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </Tooltip>
          )
        }
        sx={{
          '& .MuiDialogContent-root': {
            minHeight: { sx: '100vh', sm: '35vh' },
            maxHeight: { sx: '100vh', sm: '70vh' },
          },
          '& form': {
            flex: '1 1 auto !important',
          },
        }}
        actions={
          <>
            <Stack
              width="100%"
              direction="row"
              justifyContent={isEdit ? 'space-between' : 'flex-end'}
              alignItems="center"
            >
              <ConfirmDialog
                open={isConfirmingDelete}
                onClose={() => setConfirmingDelete(false)}
                onConfirm={handleDelete}
                loading={isDeleting}
              />
              {isEdit && (
                <LoadingButton
                  color="error"
                  onClick={() => setConfirmingDelete(true)}
                  loading={isDeleting}
                >
                  Delete Recurring Donation
                </LoadingButton>
              )}

              <LoadingButton
                size="large"
                variant="contained"
                loading={isSubmitting}
                onClick={() => {
                  if (isDirty && !Object.keys(errors).length) {
                    setConfirmationCreate(true);
                  } else {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                {isEdit ? 'Save' : 'Create'}
              </LoadingButton>
            </Stack>

            <ConfirmDialog
              open={isConfirmationCreate}
              onClose={() => setConfirmationCreate(false)}
              onConfirm={handleConfirmCreation}
              title="Are you sure?"
              textConfirmButton="Continue"
              textCancelButton="No, go back"
              sizeButton="small"
              bgColorConfirm="primary"
            >
              {isEdit ? (
                <Typography>{`Your changes will take effect with the next occurrence on ${fShortDate(getNextFutureOccurrence(watchStartDate, watchPeriod!))}`}</Typography>
              ) : (
                <Typography>
                  {isExpired() ? (
                    `Creating this recurring donation immediately adds ${calculateImmediatelyDonation()} donations, but doesn’t schedule any donations in the future.`
                  ) : (
                    <>
                      {isAfter(watchStartDate, new Date()) &&
                        `Creating this recurring donation will add donations ${watchPeriod?.toLowerCase()} starting on ${fShortDate(watchStartDate)}.`}

                      {isBefore(
                        watchStartDate,
                        new Date() || isEqual(watchStartDate, new Date())
                      ) &&
                        `Creating this recurring donation will immediately add ${calculateImmediatelyDonation()} donations and continue adding them ${watchPeriod?.toLowerCase()} as specified.`}
                    </>
                  )}
                </Typography>
              )}
            </ConfirmDialog>
          </>
        }
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
          <Stack width={{ sx: '50vh', sm: '100vh' }} direction="column" spacing={2}>
            <Tooltip title={isEdit ? alertEditMessage : ''}>
              <Box>
                <RHFAutocomplete
                  autoFocus
                  name={`donor`}
                  label="Donor"
                  required
                  fullWidth
                  freeSolo={false}
                  disabled={formDisabled || isEdit}
                  options={[...donorList, { id: 'create-new', name: '', memberNumbers: {} } as any]}
                  getOptionLabel={(donor) => donor.name || fReversedName(donor, watchedYear)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) =>
                    option.id === 'create-new' ? (
                      <li key="create-new" {...props}>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          sx={{ fontWeight: 700, color: (theme) => theme.palette.primary.dark }}
                        >
                          <Iconify icon="icons8:plus" width={20} height={20} />
                          <Typography variant="body2">Create New Donor</Typography>
                        </Stack>
                      </li>
                    ) : (
                      <li {...props} key={option.id}>
                        {option.name || fReversedName(option, watchedYear)}
                      </li>
                    )
                  }
                  beforeOnChange={(newValue, commitChange) => {
                    if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
                      setCreateDonorOpen(true);
                    } else {
                      commitChange();
                    }
                  }}
                />
              </Box>
            </Tooltip>

            <RHFAutocomplete
              name={`category`}
              label="Category"
              required
              fullWidth
              freeSolo={false}
              disabled={formDisabled}
              options={[
                ...sortBy(categories, (c) => c.name.toUpperCase()),
                { id: 'create-new', name: '', description: '' } as Category.Category,
              ]}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={renderCategoryOption}
              getOptionLabel={(option) => option.name}
              beforeOnChange={(newValue, commitChange) => {
                if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
                  setCreateCategoryOpen(true);
                } else {
                  commitChange();
                }
              }}
            />

            <RHFTextField
              required
              name="amount"
              type="number"
              label="Amount"
              disabled={formDisabled}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputProps={{ maxLength: 6 }}
            />

            <RHFAutocomplete
              name="paymentMethod"
              label="Payment Method"
              fullWidth
              freeSolo={false}
              disabled={formDisabled}
              options={paymentMethodsNoGIK}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={renderPaymentMethodOption}
            />

            <RHFCheckbox
              name="nonReceiptable"
              label="Non-receiptable"
              sx={{ minWidth: 140, height: 56 }}
            />
          </Stack>
          <Stack width={{ sx: '100vh', sm: '50vh' }} direction="column" spacing={2}>
            <Tooltip title={isEdit ? alertEditMessage : ''}>
              <Box>
                <RHFDatePicker
                  required
                  name="startDate"
                  label="Start Date"
                  disabled={isEdit}
                  minDate={subYears(fDateToYearStart(new Date()), 3)}
                  maxDate={addYears(new Date(), 1)}
                />
              </Box>
            </Tooltip>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <FormControl
                variant="outlined"
                error={!!errors.period}
                sx={{ width: '100%', height: '72px' }}
              >
                <Tooltip title={isEdit ? alertEditMessage : ''}>
                  <Box>
                    <RHFSelect fullWidth name="period" label="Repeat" disabled={isEdit}>
                      {repetition.map((p) => (
                        <MenuItem key={p} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Box>
                </Tooltip>
              </FormControl>
            </Stack>
            <Stack paddingLeft={1} sx={{ width: '100%' }}>
              <RHFRadioGroup
                name="endingOption"
                label="Ends"
                sx={{ gap: 1, width: '100%', marginRight: '-16px' }}
                onChange={(event) => {
                  const selectedOption = event.target.value as EndingOption;
                  setValue('endingOption', selectedOption);
                  clearErrors('endDate');
                  clearErrors('occurrenceLimit');

                  if (selectedOption !== EndingOption.On) {
                    setValue('endDate', null);
                  }

                  if (selectedOption !== EndingOption.After) {
                    setValue('occurrenceLimit', null);
                  }
                }}
                options={[
                  {
                    value: EndingOption.Never,
                    disabled: isEdit,
                    label: (
                      <Tooltip title={isEdit ? alertEditMessage : ''}>
                        <Typography variant="body2">Never</Typography>
                      </Tooltip>
                    ),
                  },
                  {
                    value: EndingOption.On,
                    disabled: isEdit,
                    label: (
                      <Stack
                        alignItems="center"
                        flexDirection="row"
                        gap={4}
                        justifyContent="space-between"
                        sx={{ width: '45vh' }}
                      >
                        <Tooltip title={isEdit ? alertEditMessage : ''}>
                          <Typography variant="body2">On</Typography>
                        </Tooltip>

                        <Tooltip title={isEdit ? alertEditMessage : ''}>
                          <Box sx={{ width: '100%' }}>
                            <RHFDatePicker
                              required
                              fullWidth
                              sx={{ flexGrow: 1 }}
                              name="endDate"
                              label="Ending Date"
                              minDate={subYears(new Date(), 10)}
                              maxDate={addYears(new Date(), 1)}
                              disabled={watchEndingOption !== EndingOption.On || isEdit}
                            />
                          </Box>
                        </Tooltip>
                      </Stack>
                    ),
                  },
                  {
                    value: EndingOption.After,
                    disabled: isEdit,
                    label: (
                      <Stack alignItems="center" flexDirection="row" gap={2} sx={{ width: '45vh' }}>
                        <Tooltip title={isEdit ? alertEditMessage : ''}>
                          <Typography variant="body2">After</Typography>
                        </Tooltip>

                        <Tooltip title={isEdit ? alertEditMessage : ''}>
                          <Box sx={{ width: '100%' }}>
                            <RHFTextField
                              fullWidth
                              sx={{ flexGrow: 1 }}
                              type="number"
                              name="occurrenceLimit"
                              label="Occurrences"
                              disabled={watchEndingOption !== EndingOption.After || isEdit}
                            />
                          </Box>
                        </Tooltip>
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
            {watchedValues && (
              <Stack>
                <Typography variant="body2">
                  {watchPeriod &&
                    watchStartDate &&
                    `Repeats: ${watchPeriod} ${getLabelFrequency(watchPeriod!, watchStartDate)}`}
                </Typography>
                {isEdit && !isExpired() && (
                  <Typography variant="body2">
                    {watchPeriod &&
                      watchStartDate &&
                      `Next occurrence: ${fShortDate(getNextFutureOccurrence(watchStartDate, watchPeriod!))} `}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Dialog>
    </>
  );
}
