import { useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { forEach } from 'lodash';

import PATHS from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';
import useFormat from '@hooks/useFormat';

import { useContext } from '@pages/donors/useContext';
import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import ReportLayout from '@pages/reports/ReportLayout';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import * as analytics from '@fire/analytics';

import ReportViewer from '@pages/reports/ReportViewer';

// ----------------------------------------------------------------------
export default function Export() {
  const { type, columns, data, dateFrom, dateTo, orderBy } = useContext();
  const navigate = useNavigate();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const { fCurrency, fUnCurrency } = useFormat();
  const { org } = useOrg();
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [pdfBlob, setBlob] = useState<Blob>();

  // ----- Data mapping -------
  const visibleColumns = useMemo(() => columns.filter((c) => c.visible), [columns]);
  let totals: { id: string; total: number }[] = useMemo(() => [], []);
  const rows = useMemo(
    () =>
      data.map((item: any) =>
        visibleColumns.map((column) => {
          if (['currency', 'number'].includes(column.type || '')) {
            const amount =
              column.type === 'currency' ? fUnCurrency(item[column.id]) : Number(item[column.id]);
            const total = totals.find((t) => t.id === column.id);
            if (total) total.total += amount;
            else totals.push({ id: column.id, total: amount });
          }
          if (column.type === 'boolean') return item[column.id] ? 'Yes' : 'No';
          else return item[column.id];
        })
      ),
    [data, visibleColumns, fUnCurrency, totals]
  );

  // ----- Data mapping -------
  const tableData: PdfTableData = useMemo(() => {
    // TODO: some generics should be applied here, based if its number etc.
    const selectedColumns = columns.filter((c) => c.visible && !c.internal);
    const totalWidth = selectedColumns.reduce((n, { pdfWidth }) => n + Number(pdfWidth), 0);
    const widthMultiplier = 99 / totalWidth; // for safety, only use 99% of the width
    let remainingWidth = 100;
    const header: PdfTableHeaderProps[] = selectedColumns.map((c) => {
      const cellWidth = (c.pdfWidth || 10) * widthMultiplier;
      const width = remainingWidth - cellWidth >= 0 ? cellWidth : remainingWidth;
      remainingWidth -= width;

      return {
        name: c.label,
        width,
        align: c.align as any,
      };
    });

    let columnsSinceLast = 0,
      initialSkipped = 0;
    let summary: PdfTableSummaryField[] = [];
    forEach(selectedColumns, (c, index) => {
      if (['currency', 'number'].includes(c.type || '')) {
        const total = totals.find((t) => t.id === c.id);
        const saveCount = columnsSinceLast;
        columnsSinceLast = 1;
        summary.push({
          columns: !summary.length ? 1 : saveCount,
          text:
            c.type === 'currency' ? fCurrency(total?.total || 0) : total?.total.toString() || '',
        });
      } else {
        columnsSinceLast++;
        if (!summary.length) initialSkipped++;
      }
    });
    if (summary.length && initialSkipped > 0)
      summary = [{ columns: initialSkipped, text: `Total ${rows.length} ${type}s` }, ...summary];

    return { header, items: rows, summary };
  }, [columns, fCurrency, rows, totals, type]);

  // --------------------------------------------------
  const handleClose = () => {
    if (type === 'donation') {
      navigate(PATHS.org.donations.root);
    } else {
      navigate(PATHS.org.donors.root);
    }
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleComplete = (pdfBlob: Blob) => {
    setBlob(pdfBlob);
  };

  const handleLoading = (isLoading: boolean) => {
    isLoading && setBlob(undefined);
  };

  return (
    <Dialog
      title={type === 'donor' ? 'Donors export' : 'Donations export'}
      maxWidth="lg"
      sx={{ '& .MuiPaper-root': { height: '100vh' } }}
      onClose={handleClose}
      actions={
        <Button
          size="large"
          variant="contained"
          onClick={handleOpenMenu}
          disabled={!pdfBlob}
        >
          Download
        </Button>
      }
    >
      <PdfCsvMenu
        tableData={tableData}
        pdfBlob={pdfBlob}
        basename={`export_${type === 'donor' ? 'donors' : 'donations'}`}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.exportPDF()}
        analyticsCsvFn={() => analytics.donation.exportCSV()}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" height="100%">
        <Grid item xs={12} md={3}>
          <ReportLayout
            orientation={orientation}
            setOrientation={setOrientation}
            size={size}
            setSize={setSize}
          />
        </Grid>

        <Grid item xs={12} md={9} height="100%" display="flex">
          <ReportViewer
            onComplete={handleComplete}
            onLoading={handleLoading}
            documentProps={{
              title: type === 'donor' ? 'Donors export' : 'Donations export',
              description: 'desc',
              dateReport: new Date(),
              dateFrom: dateFrom,
              dateTo: dateTo,
              orderBy: orderBy?.label,
              orgName: org!.name,
              data: tableData,
              orientation: orientation,
              size: PageSize[size],
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
